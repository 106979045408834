@import 'styles/typography.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .primary-btn {
    @apply inline-flex items-center justify-center border text-base font-medium rounded-lg  shadow-sm  bg-blue-light hover:bg-blue-medium hover:text-white focus:outline-none;
  }
  .text-btn {
    @apply inline-flex text-center xl:text-left items-center lg:justify-start justify-center border lg:border-none  text-base font-medium rounded-lg bg-blue-light focus:outline-none
    lg:bg-grey-darkest px-1 md:px-3 lg:px-0 py-2.5  lg:py-5 w-24 md:w-full text-xs md:text-lg lg:text-2xl;
  }
}
input[type='radio'] {
  transform: scale(0.7);
}
